import React from "react";
import "./about.css";
import AboutImg from "./about.png";

const About = () => {
    return (
        < div className="home-about-main">
        <div className="container">
            <div className="row">

                {/* Title */}
                <div className="col-12 home-about-main-title">
                 <h1>About WebDesignPuja</h1>
                </div>

                {/* logo */}
                <div className="col-6 home-about-main-logo">
                    <img src={AboutImg} alt="not found" className="home-about-main-logo-img" />
                </div>

                {/* Content */}
                <div className="col-6 home-about-main-content">
                    {/*  */}
                             <p>
                             Web Solutions India is one of the fastest growing IT companies in Bihar,
                              serving clients across India and United State. We broadly specialise in Software
                               Development, Website-Designing and Digital Marketing solutions.
                             </p>
                </div>
                
            </div>
        </div>
        </div>
    );
};

export default About ;