import Carousel from 'react-bootstrap/Carousel';
import Design from "./Slider1.png";
import Devlopment from "./slider2.png";
import Services from "./slider3.png";
import Relationship from "./slider4.png";
import "./Slider.css";

function Slider() {
    return (
        <Carousel data-bs-theme="dark">
            {/* first slider */}
            <Carousel.Item>
                <div className="container">
                    <div className='row silder-content-mobile-screen-odd'>
                        <div className='col-6 slider-content-sec'>
                            <div>
                                <div className='row'>
                                    <div className='col-12 slider-content-title'><h5>Website Designing</h5></div>
                                    <div className='col-12 slider-content-description'><p> Designing - blog posts, marketing copy, photos, 
                                        logos, embedded videos, podcasts, and any other creative elements on a site.<br /></p>
                                    </div>
                                    <div className='col-12'>&nbsp;</div>
                                </div>
                            </div>
                        </div>

                        <div className='col-6 slider-content-sec'>
                            <img
                                className="d-block slider-image-size-set"
                                src={Design}
                                alt="First slide"
                            />
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            {/* end first slider */}

            {/* second slider */}
            <Carousel.Item>
                <div className="container">
                    <div className='row silder-content-mobile-screen-even'>

                        <div className='col-6 slider-content-sec'>
                            <img
                                className="d-block slider-image-size-set"
                                src={Devlopment}
                                alt="First slide"
                            />
                        </div>

                        <div className='col-6 slider-content-sec'>
                            <div>
                                <div className='row'>
                                <div className='col-12 slider-content-title'><h5>Website Devlopment</h5></div>
                                    <div className='col-12 slider-content-description'><p> software development lifecycle 
                                    planning, analysis, design, implementation, testing, deployment, and maintenance.<br /></p>
                                    </div>
                                    <div className='col-12'>&nbsp;</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Carousel.Item>
            {/* End second slider */}

            {/* Third Slider */}
            <Carousel.Item>
                <div className="container">
                    <div className='row silder-content-mobile-screen-odd'>
                        <div className='col-6 slider-content-sec'>
                            <div>
                                <div className='row'>
                                <div className='col-12 slider-content-title'><h5>Services</h5></div>
                                    <div className='col-12 slider-content-description'><p>  We scrutinize each client’s business 
                                        needs, give pragmatic advice on the software design, and say an honest to unfeasible ideas.<br /></p>
                                    </div>
                                    <div className='col-12'>&nbsp;</div>
                                </div>
                            </div>
                        </div>

                        <div className='col-6 slider-content-sec'>
                            <img
                                className="d-block slider-image-size-set"
                                src={Services}
                                alt="First slide"
                            />
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            {/* End third Slider */}

            {/* Fourth Slider */}
            <Carousel.Item>
                <div className="container">
                    <div className='row silder-content-mobile-screen-even'>
                    <div className='col-6 slider-content-sec'>
                            <img
                                className="d-block slider-image-size-set"
                                src={Relationship}
                                alt="First slide"
                            />
                        </div>

                        <div className='col-6 slider-content-sec'>
                            <div>
                                <div className='row'>
                                <div className='col-12 slider-content-title'><h5>Client Relationship</h5></div>
                                    <div className='col-12 slider-content-description'><p> Include knowledge base articles, user guides,
                                         and assist clients in using your products or services effectively.<br /></p>
                                    </div>
                                    <div className='col-12'>&nbsp;</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Carousel.Item>
            {/* End fourth slider */}

        </Carousel>
    );
}

export default Slider;