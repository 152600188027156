import React from "react";
import "./courses.css";
import TeamProgram1 from "./team-program/teamprog-1.jpeg";
import TeamProgram2 from "./team-program/teamprog-2.jpeg";
import TeamProgram3 from "./team-program/tamprog-3.jpeg";
import { Link } from "react-router-dom";

const Courses = () => {
    return (
        <div className="container" >
        <h3 className="professionalContentHeading">We are Provided</h3>
        <section id="SellCourses">

          {/* web devloper courses section start */}
        <div className="team_prog">
          <img src={TeamProgram1} alt="img not found" className="team_prog_img" />
          <h3>Web Devlopment</h3>
          <p>
          primary role is to ensure the website is visually appealing and easy to navigate.
          </p>
          <ul>
            <li>Frontend Devlopment</li>
            <li>Backend Devlopment</li>
            <li>DBMS</li>
            <li>Hosting</li>
          </ul>
          <hr />
          <h3>Piyush Kumar</h3>
          <span className="team_prog_professions">Web Devloper</span> <br />
          <div className="team_prog_button">
          <Link to="/team_msg" className="team_prog_but">Apply Now</Link>
          </div>
        </div>
        {/* web devloper courses section end */}

        {/* soft skills courses section start */}
        <div className="team_prog">
          <img src={TeamProgram2} alt="img not found" className="team_prog_img" />
          <h3>Soft Skills Training</h3>
          <p>
          Soft skills training is training that focuses on the development of abilities.
          </p>
          <ul>
            <li>Communication</li>
            <li>Teamwork</li>
            <li>Problem Solving</li>
            <li>Employee's Technical Ability</li>
          </ul>
          <hr />
          <h3>Puja Singh</h3>
          <span className="team_prog_professions">professionals</span> <br />
          <div className="team_prog_button">
          <Link to="/team_msg" className="team_prog_but">Apply Now</Link>
          </div>
        </div>
        {/* soft skills courses section end */}

        {/* digital marketing courses section start */}
        <div className="team_prog">
          <img src={TeamProgram3} alt="img not found" className="team_prog_img" />
          <h3>Digital Marketing</h3>
          <p>
          Digital Marketing is a promoting and selling products and services through the internet.
          </p>
          <ul>
            <li>SMO Services</li>
            <li>SEO Services</li>
            <li>PPC Services</li>
            <li>Youtube Optimization</li>
          </ul>
          <hr />
          <h3>Puja Singh</h3>
          <span className="team_prog_professions">Digital Marketer</span> <br />
          <div className="team_prog_button">
            <Link to="/team_msg" className="team_prog_but">Apply Now</Link>
          </div>
        </div>
        {/* digital marketing courses section end */}

      </section>
        </div >
    )
};

export default Courses;