import React, { useState } from "react";
import "../css/Navbar.css";
import { FaFacebookSquare, FaInstagramSquare, FaWhatsappSquare, FaYoutubeSquare } from "react-icons/fa";
import Logo from "../../image/Logo.png";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa6";
import { GiHamburgerMenu } from "react-icons/gi";


const Navbar = () => {

    React.useEffect(() => {
        const body = document.querySelector('*');
    
        body.scrollIntoView({
            top: 0,
            behavior: 'smooth'
    
        }, 500)
    
      }, []);


    const[showMediaIcons, setShowMediaIcons] = useState(false)
    return (
        <>
            <nav className="main-nav">

                {/* Logo section */}
                <div className="nav-logo">
                    <Link to="/"><img src={Logo} alt="not found" /></Link>
                </div>

                {/* End logo section */}
                {/* Component section */}
                <div className={showMediaIcons ? "nav-menu-link nav-mobile-menu-link" : "nav-menu-link" }>
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/services">Services</Link>
                        </li>
                        <li>
                            <Link to="/contactUs">ContactUs</Link>
                        </li>
                    </ul>
                </div>
                {/* End Component section */}
                {/* social Media Link */}
                <div className="nav-social-media">
                    <ul className="nav-social-media-desktop" >
                        <li>
                            <Link to="https://www.facebook.com/profile.php?id=61559784219798" target="facebook">
                                <FaFacebookSquare className="nav-facebook" />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://www.instagram.com/webdesignpuja/" target="instragram">
                                <FaInstagramSquare className="nav-instragram" />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://www.youtube.com/@Webdesignpuja" target="youtube">
                                <FaYoutubeSquare className="nav-youtube " />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://www.linkedin.com/company/webdesignpuja/?viewAsMember=true" target="linkdin">
                                <FaLinkedin className="nav-linkdin" />
                            </Link>
                        </li>
                        <li>
                            <Link to="https://api.whatsapp.com/send?phone=919905532741" target="whatsaap">
                                <FaWhatsappSquare className="nav-whatsaap" />
                            </Link>
                        </li>
                    </ul>

                    {/* humbarger menu */}
                    <div className="nav-humbarger-menu">
                        <GiHamburgerMenu onClick={() => setShowMediaIcons(!showMediaIcons)} />
                    </div>
                </div>
                {/* End social Media Link */}


            </nav>
        </>
    );
};

export default Navbar;