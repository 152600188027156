import React, { useState } from "react";
import "./contectUs.css";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";

const ContectUsPage = () => {

    React.useEffect(() => {
    const body = document.querySelector('*');
    
        body.scrollIntoView({
            top: 0,
            behavior: 'smooth'
    
        }, 500)
    
      }, []);

    const [formData, setFormData] = useState({
        fname: "",
        lname: "",
        city: "",
        state: "",
        pin: "",
        email: "",
        massage: ""
    });

    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        // Validate email
        if (!formData.email) {
            newErrors.email = "Email is required";
            isValid = false;
        }

        // Validate password
        if (!formData.massage) {
            newErrors.massage = "massage is required";
            isValid = false;
        }

        // Validate password
        if (!formData.pin) {
            newErrors.pin = "pin is required";
            isValid = false;
        }

        // Validate password
        if (!formData.state) {
            newErrors.state = "state is required";
            isValid = false;
        }

        // Validate password
        if (!formData.city) {
            newErrors.city = "city is required";
            isValid = false;
        }

        // Validate password
        if (!formData.lname) {
            newErrors.lname = "last name is required";
            isValid = false;
        }

        // Validate password
        if (!formData.fname) {
            newErrors.fname = "first name is required";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            // Form is valid, you can submit or process the data here
            console.log("Form data:", formData);
            setSubmitted(true); // Set a submitted flag
        } else {
            // Form is not valid, display error messages
        }
    };

    const handleToClose = () => {
        setSubmitted(false);
    };

    const isFormValid = Object.keys(errors).length === 0;

    return (
        <>
            <div className="contectus-main-page container">
                <div className="row">
                    {/* contact us page address */}
                    <div className="col-6 content-page-division">
                        <h3 className="contect-page-title">Contact Information</h3>
                        <p className="content-page-description">
                            {/* bihar state */}
                            <strong>Bihar </strong>Near Samastipur Central School
                            Kashipur Samastipur, Sonwarsha Chowk Bihar Pin Code - 848101 <br />
                            <strong>Contect Person </strong> Puja Singh<br />
                            <strong>Contect No. </strong>+91 8882057088<br />
                            <strong>E-mail </strong>webdesignpuja@gmail.com<br />
                            <br /><br /><br />

                            {/* us state */}
                            <strong>United States </strong>Jefferson City, Missouri Clayton Drive<br />
                            Pin Code - 405 <br />
                            <strong>Contect Person </strong> Puja Singh<br />
                            <strong>Contect No. </strong>+1 (573)462-2903<br />
                            <strong>E-mail </strong>webdesignpuja@gmail.com<br />

                        </p>

                    </div>
                    {/* End contact us page address */}
                    {/* contact us page mssage section */}
                    <div className="col-6 content-page-division">
                        {submitted ? (
                            <Dialog open={handleSubmit} onClose={handleToClose}>
                                <DialogTitle>{"Massage Send Successfully?"}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Your Massage Was Sended Successfully Submitted.<br /> our Team was
                                        Connected as soon as Possible.<br />
                                        <strong>Thanku For Connect Me.</strong>
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleToClose}
                                        color="primary" autoFocus>
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>
                            // <div className="success-message">Login successful!</div>
                        ) : (
                            <form className="container" onSubmit={handleSubmit}>
                                <div className="form-row row">
                                    <div className="col-12">
                                        <h3 className="contect-page-title">Query or Contact Our Team</h3>
                                    </div>

                                    <div className="col-6 mb-4">
                                        <label htmlFor="validationServer01">First name</label>
                                        <input
                                            type="text"
                                            name="fname"
                                            value={formData.fname}
                                            onChange={handleInputChange}
                                            className="form-control is-valid"
                                            placeholder="First name"
                                            defaultValue="Puja"
                                            required />
                                        {errors.email && <div className="error">{errors.email}</div>}
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="validationServer02">Last name</label>
                                        <input
                                            type="text"
                                            className="form-control is-valid"
                                            name="lname"
                                            value={formData.lname}
                                            onChange={handleInputChange}
                                            placeholder="Last name"
                                            defaultValue="Singh"
                                            required />
                                        {errors.lname && <div className="error">{errors.lname}</div>}
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="validationServer03">City</label>
                                        <input
                                            type="text"
                                            name="city"
                                            value={formData.city}
                                            onChange={handleInputChange}
                                            className="form-control is-valid"
                                            placeholder="City"
                                            required />
                                        {errors.city && <div className="error">{errors.city}</div>}
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="validationServer04">State</label>
                                        <input
                                            type="text"
                                            name="state"
                                            value={formData.state}
                                            onChange={handleInputChange}
                                            className="form-control is-valid"
                                            placeholder="State"
                                            required />
                                        {errors.state && <div className="error">{errors.state}</div>}
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="validationServer05">Pin Code</label>
                                        <input
                                            type="text"
                                            className="form-control is-valid"
                                            name="pin"
                                            value={formData.pin}
                                            onChange={handleInputChange}
                                            placeholder="Pin"
                                            required />
                                        {errors.pin && <div className="error">{errors.pin}</div>}
                                    </div>

                                    <div className="col-6 mb-3">
                                        <label htmlFor="validationServer05">E-mail</label>
                                        <input
                                            type="mail"
                                            className="form-control is-valid"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            placeholder="Mail"
                                            required />
                                        {errors.email && <div className="error">{errors.email}</div>}
                                    </div>

                                    <div className="col-12 mb-3">
                                        <label>Query</label>
                                        <input
                                            type="text"
                                            className="form-control is-valid"
                                            name="massage"
                                            value={formData.massage}
                                            onChange={handleInputChange}
                                            placeholder="Query"
                                            required />
                                        {errors.massage && <div className="error">{errors.massage}</div>}
                                    </div>

                                </div>

                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    disabled={!isFormValid} >
                                    Submit form
                                </button>

                            </form>
                        )}
                    </div>
                    {/* End contact us page mssage section */}
                </div>
            </div>
        </>
    );
};

export default ContectUsPage;
