import React from "react";
import Navbar from "./component/Navbar/Navbar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./page/Home/Home";
import ContectUsPage from "./page/ContactUs/ContectUsPage";
import ServicesPage from "./page/Services/ServicesPage";
import AboutPage from "./page/About/AboutPage";
import Footer from "./component/Footer/footer";
import TeamMassage from "./component/Home/Courses/Team_Massage_me";

function App() {

  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contactUs" element={<ContectUsPage />} />
          <Route path="/team_msg" element={<TeamMassage />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
