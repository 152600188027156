import React from "react";
import "./Team.css";
import Team2 from "./mentee/delottie-2.jpg";
import Team4 from "./mentee/delottie-4.jpg";

const Team = () => {
    return (
        <>
            <div className="Our_Word home-about-main-title">
                <h2 className="Our_Word_maintitle">Our Team </h2>
            </div>
            <section id="Our_Word">
                <div className="Our_Word1">
                    <div className="Our_Word_imgSection">
                        <img className="Our_Word_img" src={Team2} alt="not found" />
                    </div>
                    <div className="Our_Word_Content">
                        <p><strong>Founder of WebDesignPuja</strong></p>
                        <button className="Our_Word_Btn">
                            Puja singh<span className="Our_Word_DotButton">.</span>
                        </button>
                    </div>
                </div>
                <div className="Our_Word1">
                    <div className="Our_Word_imgSection">
                        <img className="Our_Word_img" src={Team2} alt="not found" />
                    </div>
                    <div className="Our_Word_Content">
                        <p>Digital Marketer</p>
                        <button className="Our_Word_Btn">
                            Puja Singh<span className="Our_Word_DotButton">.</span>
                        </button>
                    </div>
                </div>
                <div className="Our_Word1">
                    <div className="Our_Word_imgSection">
                        <img className="Our_Word_img" src={Team4} alt="not found" />
                    </div>
                    <div className="Our_Word_Content">
                        <p>Designing & Devlopment</p>
                        <button className="Our_Word_Btn">
                            Piyush Kumar<span className="Our_Word_DotButton">.</span>
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
};

export default Team;