import React from "react";
import "./aboutPage.css";
import AboutImg from "./Main-about.png";

const AboutPage = () => {

    React.useEffect(() => {
        
        const body = document.querySelector('*');
    
        body.scrollIntoView({
            top: 0,
            behavior: 'smooth'
    
        }, 500)
    
      }, []);

    return (
        <>
            <div className="about-main-page container">
                <div className="row">
                    {/* about-main-page-title */}
                    <div className="col-3 about-main-page-title">
                        <h3>All About Us!</h3>
                    </div>

                    {/* about-main-page-content */}
                    <div className="col-9 about-main-page-description">
                        <p>
                            <b>WebDesignPuja </b>Grown successfully in the digital and web industry, our expertise
                            ranges from be spoke Software Products, Web Designing, Web Development,
                            Mobile App, e-Commerce, Domain & Web Hosting to building Corporate Identity,
                            managing Social Media Marketing and Search Engine Optimization services. Our
                            well-qualified experts provide best-in-class resolutions for all customer
                            requirements. Our team members share a passion for pushing the limits of the
                            technology frontier and possess an unwavering commitment to quality, efficieny
                            and innovation.
                        </p>
                        <p>WebDesignPuja centralizes on professional interactive development, Designing
                            and ecommerce solutions.   It helps the brand’s influence with
                            innovation and to communicate and associate with consumers using
                            UX and UI elements to get more than industry standards reach.
                            WebDesignPuja vision conceived out of a deep commitment to a
                            digital method and enthusiasm for developing advances. our
                            developers are highly skilled and hold a Magento Certified
                            Developer level with have a vast experience in different technological
                            solutions for every need.</p>
                        <h5>Values</h5>
                        <ul className="about-main-content-list-style">
                            <li>
                                <b>Innovation:</b> We build new designs for every client requirement. We
                                do not follow any standard template and the designs are developed
                                on real time basis per the requirement of the client.
                            </li>
                            <li>
                                <b>Commitment:</b> We are committed to our best in Quality, Design, and Style
                                within the planned timelines.
                            </li>
                            <li>
                                <b>Transparency:</b> We assure transparency to all our clients assuring them updates
                                on a real-time basis and complete data in report format of our services for them.
                            </li>
                        </ul>
                        <h5>Mission</h5>
                        <p>
                            We aim to provide reliable and superior state-of-the-art software solutions
                            and designs to our clients at an affordable price. We are working towards bringing
                            quality, simplicity and scalability to our solutions leading to professional growth
                            for our clients as well as employees. We aim to be a part of the client business that
                            serves them with bigger profits.
                        </p>
                    </div>

                    <div className="col-12">
                        <div className="row">
                            <div className="col-6 about-main-page-subtitle-mobile">
                                <h5>Vision</h5>
                                <p>
                                    Our Vision is to become a leading global technology provider of the country.
                                    Our organisation exists to acquire global competence through innovation and
                                    product development. We aspire to become the ideators of the IT-industry and
                                    be pioneers of new frameworks.
                                </p>
                                <ul className="about-main-content-list-style">
                                    <li>
                                        Heavy experience in working with clients belonging to different domains
                                    </li>
                                    <li>
                                        Working in partnership with our clients
                                    </li>
                                    <li>
                                        Outstanding relationship development; for our success mantra is ‘Client Success = Our Success.’
                                    </li>
                                    <li>
                                        100% record of on-time delivery of the planned services
                                    </li>
                                    <li>Cost effective solution</li>
                                    <li>
                                        Strong analytical skills and data-driven thinking
                                    </li>
                                    <li>
                                        Superior maintenance services and high Customer Satisfaction
                                    </li>
                                    <li>
                                        Developers have experience and development knowledge of all Frameworks and technologies
                                    </li>
                                    <li>24x7 Service Support.</li>
                                </ul>
                            </div>

                            <div className="col-6 about-main-page-subtitle-mobile main-about-about-image">
                                <img src={AboutImg} alt="not found" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutPage;