import React from "react";
import SideLogo from "./professional/sideLogo.jpeg";
import "./professional.css";

const Professional = () => {
    return (
        <div className="container" >
            <h3 className="professionalContentHeading">Goal of WebDesignPooja</h3>
            <section id="professional">
                <div className="ProfessionalContent">
                    <p>
                        <strong>WebDesignPooja</strong> is a website development company whose mission is to create websites for
                        everyone. Through this, a target has been set to provide websites to every small and big
                        industry and businessmen. His viewpoint is that everyone should have their own website,
                        whether he is a big businessman or a small businessman. This medium will contribute to
                        the campaign of Digital India so that people will be able to move forward. And the
                        dream of Digital India will come true when people start doing their work through online
                        medium.
                    </p>
                </div>
                <div id="Professionalimg">
                    <div className="professionalImg">
                        <img src={SideLogo} alt="not found" />
                    </div>
                    <div className="professionlimg_sidebar" />
                </div>
            </section>
        </div >
    )
};

export default Professional;