import React from "react";
import "./Home.css";
import Slider from "../../component/Home/slider/Slider";
import About from "../../component/Home/About/About";
import Professional from "../../component/Home/Professional-cartificates/Professional";
import Courses from "../../component/Home/Courses/Courses";
import Team from "../../component/Home/Team/Team";

const Home = () => {
  React.useEffect(() => {
    const body = document.querySelector('*');

    body.scrollIntoView({
        top: 0,
        behavior: 'smooth'

    }, 500)

  }, []);
  
  return (
    <>
    <Slider />
    <About />
    <Professional />
    <Courses />
    <Team />
    </>
  );
};

export default Home ;