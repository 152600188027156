import React from "react";
import "./footer.css";
import { FaFacebookSquare, FaInstagramSquare, FaWhatsappSquare, FaMailBulk, FaRegGem } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa6";

const footer = () => {

    return (
        <>

            {/* Footer */}
            <footer className="text-center text-lg-start bg-body-tertiary text-muted">
                {/* Section: Social media */}
                <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                    {/* Left */}
                    <div className="me-5 d-none d-lg-block">
                        <span>Get connected with us on social networks:</span>
                    </div>
                    {/* Left */}
                    {/* Right */}
                    <div>
                        <Link to="https://www.facebook.com/profile.php?id=61559784219798" target="facebook" className="me-4 text-reset">
                            <FaFacebookSquare className="nav-facebook" />
                        </Link>

                        <Link to="mailto:webdesignpuja@gmail.com" target="mail" className="me-4 text-reset">
                            <FaMailBulk className="nav-gmail" />
                        </Link>

                        <Link to="https://www.instagram.com/webdesignpuja/" target="instragram" className="me-4 text-reset">
                            <FaInstagramSquare className="nav-instragram" />
                        </Link>

                        <Link to="https://www.linkedin.com/company/webdesignpuja/?viewAsMember=true" target="linkdin" className="me-4 text-reset">
                            <FaLinkedin className="nav-linkdin" />
                        </Link>

                        <Link to="https://api.whatsapp.com/send?phone=919905532741" target="whatsaap" className="me-4 text-reset">
                            <FaWhatsappSquare className="nav-whatsaap" />
                        </Link>
                    </div>
                    {/* Right */}
                </section>
                {/* Section: Social media */}
                {/* Section: Links  */}
                <section className>
                    <div className="container text-center text-md-start mt-5">
                        {/* Grid row */}
                        <div className="row mt-3">
                            {/* Grid column */}
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                {/* Content */}
                                <h6 className="text-uppercase fw-bold mb-4">
                                    <FaRegGem className="me-3" />webdesignpuja
                                </h6>
                                <p className="about-main-content-list-style">
                                    WebDesignPuja Provided Software Products, Website Designing, Website Development, Mobile App,
                                    e-Commerce Dashboard, Domain & Hosting to building Corporate Identity, managing
                                    Social Media Marketing and Search Engine Optimization services.
                                </p>
                            </div>
                            {/* Grid column */}
                            {/* Grid column */}
                            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 footer_navbar-Link">
                                {/* Links */}
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Products
                                </h6>
                                <p>
                                <Link to="/">Home</Link>
                                </p>
                                <p>
                                <Link to="/about">About</Link>
                                </p>
                                <p>
                                <Link to="/services">Services</Link>
                                </p>
                                <p>
                                <Link to="/contactUs">ContactUs</Link>
                                </p>
                               
                            </div>
                            {/* Grid column */}
                            {/* Grid column */}
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                {/* Links */}
                                <h6 className="text-uppercase fw-bold mb-4">Contact in India</h6>
                                <p>Patna, Bihar 800002, India</p>
                                <p>webdesignpuja@gmail.com</p>
                                <p>+ 91 8882057088</p>
                                <p>+ 91 8882057088</p>
                            </div>
                            {/* Grid column */}
                            {/* Grid column */}
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                {/* Links */}
                                <h6 className="text-uppercase fw-bold mb-4">Contact in US</h6>
                                <p>Missouri Clayton Drive 405</p>
                                <p>webdesignpuja@gmail.com</p>
                                <p>+ 1 (573)462-2903</p>
                                <p>+ 1 (573)462-2903</p>
                            </div>
                            {/* Grid column */}
                        </div>
                        {/* Grid row */}
                    </div>
                </section>
                {/* Section: Links  */}
                {/* Copyright */}
                <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                    © 2024 Copyright:&nbsp;
                    <span className="text-reset fw-bold">Piyush Kumar</span>
                </div>
                {/* Copyright */}
            </footer>
            {/* Footer */}

        </>
    );
};

export default footer;