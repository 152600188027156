import React from "react";
import "./services.css";
import Seo from "./serviceimg/seo.jpg";
import Smo from "./serviceimg/smo.jpg";
import Ppc from "./serviceimg/ppc.jpg";
import DigitalMArketing from "./serviceimg/digital-marketing.jpg";
import GoogleBussiness from "./serviceimg/google-business.jpg";
import YouTubeOptimization from "./serviceimg/youtube-seo.jpg";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Frontend from "./serviceimg/front-end.jpg";
import UiUx from "./serviceimg/ui-ux.jpg";
import Backend from "./serviceimg/backend.png";
import Dbms from "./serviceimg/database.jpg";
import TestDbug from "./serviceimg/testing&dubbing.png";
import CMS from "./serviceimg/cms.png";

const ServicesPage = () => {

  React.useEffect(() => {
    const body = document.querySelector('*');

    body.scrollIntoView({
      top: 0,
      behavior: 'smooth'

    }, 500)

  }, []);

  return (
    <>


      {/* Service 9 - Bootstrap Brain Component */}
      <section className="bg-light py-3 py-md-5 py-xl-8 services-main-page">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
              <h2 className="mb-2">Our Services</h2>
              <p className="text-secondary mb-5r">
                Creation of application programs that reside on remote servers and
                are delivered to the user's device over the internet. We Provide to
                End-to-End Application Services · Database Software.
                Database Software Development · Website Design. Ecommerce Website Design ·
                Application Testing..
              </p>
              <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark" />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="container-fluid">
                <div className="row gy-3 gy-md-4">

                  {/* web devlopment title start */}
                  <div className="col-12 about-main-page-title">
                    <h3>Website Devlopment</h3>
                  </div>
                  {/* web devlopment title design end */}

                  {/* Ui ux design start */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="card border-dark">
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={UiUx} alt="Not found" className="w-100 services-page-img-size-set" /><br /><br />
                        <h4 className="mb-4">Web Design</h4>
                        <a href="https://en.wikipedia.org/wiki/Web_design" className="btn btn-outline-primary bsb-btn-circle">
                          <FaArrowAltCircleRight />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* ui ux design end */}

                  {/* front end Design Start */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="card border-dark">
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={Frontend} alt="Not found" className="w-100 services-page-img-size-set" /><br /><br />
                        <h4 className="mb-4">Front-End Devloper</h4>
                        <a href="https://en.wikipedia.org/wiki/Front-end_web_development" className="btn btn-outline-primary bsb-btn-circle">
                          <FaArrowAltCircleRight />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* front end design end */}

                  {/* Backend design start */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="card border-dark">
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={Backend} alt="Not found" className="w-100 services-page-img-size-set" /><br /><br />
                        <h4 className="mb-4">Back-End Devloper</h4>
                        <a href="https://en.wikipedia.org/wiki/Node.js" className="btn btn-outline-primary bsb-btn-circle">
                          <FaArrowAltCircleRight />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* end backend desin */}

                  {/* Dbms design start */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="card border-dark">
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={Dbms} alt="Not found" className="w-100 services-page-img-size-set" /><br /><br />
                        <h4 className="mb-4">DBMS</h4>
                        <a href="https://en.wikipedia.org/wiki/Database#Database_management_system" className="btn btn-outline-primary bsb-btn-circle">
                          <FaArrowAltCircleRight />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* DBMS design end */}

                  {/* Testing & Debugging design start */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="card border-dark">
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={TestDbug} alt="Not found" className="w-100 services-page-img-size-set" /><br /><br />
                        <h4 className="mb-4">Testing and Debugging</h4>
                        <a href="https://en.wikipedia.org/wiki/Software_testing" className="btn btn-outline-primary bsb-btn-circle">
                          <FaArrowAltCircleRight />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* Testing & Debuging Design End */}

                  {/* Cms Design start */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="card border-dark">
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={CMS} alt="Not found" className="w-100 services-page-img-size-set" /><br /><br />
                        <h4 className="mb-4">Content Management Systems</h4>
                        <a href="https://en.wikipedia.org/wiki/Content_management_system" className="btn btn-outline-primary bsb-btn-circle">
                          <FaArrowAltCircleRight />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* End Cms Design */}

                  {/* digital marketing design start */}
                  <div className="col-12 about-main-page-title">
                    <h3>Digital Marketing</h3>
                  </div>
                  {/* digital marketing design end */}

                  {/* seo services design start */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="card border-dark">
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={Seo} alt="Not found" className="w-100" /><br /><br />
                        <h4 className="mb-4">SEO Services</h4>
                        <a href="http://webdigisol.com/seo-services/" className="btn btn-outline-primary bsb-btn-circle">
                          <FaArrowAltCircleRight />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* seo services design end */}

                  {/* smo services design start */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="card border-dark">
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={Smo} alt="Not found" className="w-100" /><br /><br />
                        <h4 className="mb-4">SMO Services</h4>
                        <a href="http://webdigisol.com/smo-services/" className="btn btn-outline-primary bsb-btn-circle">
                          <FaArrowAltCircleRight />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* smo services design end */}

                  {/* ppc services design start */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="card border-dark">
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={Ppc} alt="Not found" className="w-100" /><br /><br />
                        <h4 className="mb-4">PPC Services</h4>
                        <a href="http://webdigisol.com/ppc-services/" className="btn btn-outline-primary bsb-btn-circle">
                          <FaArrowAltCircleRight />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* ppc services design end */}

                  {/* Digital marketing design start */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="card border-dark">
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={DigitalMArketing} alt="Not found" className="w-100" /><br /><br />
                        <h4 className="mb-4">Digital Marketing</h4>
                        <a href="http://webdigisol.com/digital-marketing/" className="btn btn-outline-primary bsb-btn-circle">
                          <FaArrowAltCircleRight />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* Digital marketing design end */}

                  {/* google my business design start */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="card border-dark">
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={GoogleBussiness} alt="Not found" className="w-100" /><br /><br />
                        <h4 className="mb-4">Google My Business</h4>
                        <a href="http://webdigisol.com/google-my-business-listing/" className="btn btn-outline-primary bsb-btn-circle">
                          <FaArrowAltCircleRight />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* google my business design end */}

                  {/* youtube optimization design start */}
                  <div className="col-12 col-md-6 col-lg-4">
                    <div className="card border-dark">
                      <div className="card-body text-center p-4 p-xxl-5">
                        <img src={YouTubeOptimization} alt="Not found" className="w-100" /><br /><br />
                        <h4 className="mb-4">YouTube Optimization</h4>
                        <a href="http://webdigisol.com/youtube-optimization/" className="btn btn-outline-primary bsb-btn-circle">
                          <FaArrowAltCircleRight />
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* youtube optimization design end */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default ServicesPage;